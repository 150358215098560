// Figures
.figure {
    img {
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
        margin-bottom: $paragraph-margin-bottom / 2;
    }
    .caption {
        color: $text-muted;
        font-style: italic;
    }
}
