/*
This file contains overrides for the default styles of the READTHEDOCS version picker.
These overrides are necessary to make the version picker work with the Sphinx Wagtail Theme.
*/
.rst-versions {
    position: fixed;
    bottom: 50px;
    right: 50px;
    max-width: 300px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;

    background: #1f1d1d;
    // font-family: "Lato", "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    z-index: 400;

    &.shift-up .rst-other-versions {
        display: block;
    }

    a {
        color: #2980b9;
        text-decoration: none;
    }
}

.rst-current-version {
    display: block;
    padding: 12px;
    text-align: right;
    font-size: 90%;

    cursor: pointer;
    background-color: $indigo;
    color: $white;
    *zoom: 1;

    span.fa-book {
        display: none;
    }
}

// .rst-versions .rst-current-version:before,
// .rst-versions .rst-current-version:after {
//     display: table;
//     content: "";
// }
// .rst-versions .rst-current-version:after {
//     clear: both;
// }

// .rst-versions .rst-current-version .fa,
// .rst-versions .rst-current-version .wy-menu-vertical li span.toctree-expand,
// .wy-menu-vertical li .rst-versions .rst-current-version span.toctree-expand,
// .rst-versions .rst-current-version .rst-content .admonition-title,
// .rst-content .rst-versions .rst-current-version .admonition-title,
// .rst-versions .rst-current-version .rst-content h1 .headerlink,
// .rst-content h1 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content h2 .headerlink,
// .rst-content h2 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content h3 .headerlink,
// .rst-content h3 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content h4 .headerlink,
// .rst-content h4 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content h5 .headerlink,
// .rst-content h5 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content h6 .headerlink,
// .rst-content h6 .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content dl dt .headerlink,
// .rst-content dl dt .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content p.caption .headerlink,
// .rst-content p.caption .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content table > caption .headerlink,
// .rst-content table > caption .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content .code-block-caption .headerlink,
// .rst-content .code-block-caption .rst-versions .rst-current-version .headerlink,
// .rst-versions .rst-current-version .rst-content tt.download span:first-child,
// .rst-content tt.download .rst-versions .rst-current-version span:first-child,
// .rst-versions .rst-current-version .rst-content code.download span:first-child,
// .rst-content code.download .rst-versions .rst-current-version span:first-child,
// .rst-versions .rst-current-version .icon {
//     color: #fcfcfc;
// }

// .rst-versions .rst-current-version .fa-book,
// .rst-versions .rst-current-version .icon-book {
//     float: left;
// }

// .rst-versions .rst-current-version .icon-book {
//     float: left;
// }

// .rst-versions .rst-current-version.rst-out-of-date {
//     background-color: #e74c3c;
//     color: #fff;
// }

// .rst-versions .rst-current-version.rst-active-old-version {
//     background-color: #f1c40f;
//     color: #000;
// }

.rst-versions .rst-other-versions {
    font-size: 90%;
    padding: 12px;
    color: gray;
    display: none;
}

.rst-versions .rst-other-versions hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 20px 0;
    padding: 0;
    border-top: solid 1px #413d3d;
}

.rst-versions .rst-other-versions dd {
    display: inline-block;
    margin: 0;
}

.rst-versions .rst-other-versions dd a {
    display: inline-block;
    padding: 6px;
    color: #fcfcfc;
}

// .rst-versions.rst-badge {
//     /* width: auto; */
//     /* bottom: 20px; */
//     /* right: 20px; */
//     /* left: auto; */
//     /* border: none; */
//     /* max-width: 300px; */
//     /* max-height: 90%; */
// }

// .rst-versions.rst-badge .icon-book {
//     float: none;
//     line-height: 30px;
// }
// .rst-versions.rst-badge .fa-book,
// .rst-versions.rst-badge .icon-book {
//     float: none;
//     line-height: 30px;
// }
// .rst-versions.rst-badge.shift-up .rst-current-version {
//     text-align: right;
// }
// .rst-versions.rst-badge.shift-up .rst-current-version .fa-book,
// .rst-versions.rst-badge.shift-up .rst-current-version .icon-book {
//     float: left;
// }
// .rst-versions.rst-badge.shift-up .rst-current-version .icon-book {
//     float: left;
// }
// .rst-versions.rst-badge > .rst-current-version {
//     width: auto;
//     height: 30px;
//     line-height: 30px;
//     padding: 0 6px;
//     display: block;
//     text-align: center;
// }

@media screen and (max-width: 768px) {
    .rst-versions {
        // width: 85%;
        display: none;
    }
    // .rst-versions.shift {
    //     display: block;
    // }
}
