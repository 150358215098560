a {
    color: $link-color;
    text-decoration: 2px solid underline;
    text-decoration-color: $link-border-color;
    text-underline-offset: 3px;

    &:focus,
    &:hover {
        color: $link-hover-color;
        // This is necessary because of some weird `hover` mixin in bootstrap.
        text-decoration: 2px solid underline;
    }

    &:active {
        color: $link-active-color;
        text-decoration: 2px solid underline;
    }
}
