@font-face{
    font-family: 'Source Sans 3';
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans/SourceSans3-Regular.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-stretch: normal;
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/source-sans/SourceSans3-It.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans/SourceSans3-Bold.ttf.woff2') format('woff2');
}

@font-face{
    font-family: 'Source Sans 3';
    font-stretch: normal;
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/source-sans/SourceSans3-BoldIt.ttf.woff2') format('woff2');
}

@font-face {
    font-family: "Source Code Pro";
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/source-code-pro/SourceCodePro-Regular.ttf.woff2");
}
@font-face {
    font-family: "Source Code Pro";
    font-stretch: normal;
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/source-code-pro/SourceCodePro-It.ttf.woff2");
}
@font-face {
    font-family: "Source Code Pro";
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/source-code-pro/SourceCodePro-Bold.ttf.woff2");
}
@font-face {
    font-family: "Source Code Pro";
    font-stretch: normal;
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/source-code-pro/SourceCodePro-BoldIt.ttf.woff2");
}
